import React from 'react';
import Carousel from './Carousel';
import canada0 from '../images/canada/canada0.jpg';
import canada1 from '../images/canada/canada1.jpg';
import canada2 from '../images/canada/canada2.jpg';
import canada3 from '../images/canada/canada3.jpg';
import canada4 from '../images/canada/canada4.jpg';
import canada5 from '../images/canada/canada5.jpg';
import canada6 from '../images/canada/canada6.jpg';
import canada7 from '../images/canada/canada7.jpg';
import canada8 from '../images/canada/canada8.jpg';
import canada9 from '../images/canada/canada9.jpg';
import canada10 from '../images/canada/canada10.jpg';
import canada11 from '../images/canada/canada11.jpg';

function CanadaCarousel() {
const images = [
		{
			alt: 'Moraine Lake',
			src: canada0,
			key: 0,
		},
		{
			alt: 'Sonnenuntergang in Tofino',
			src: canada1,
			key: 1,
		},
		{
			alt: 'Streifenhörnchen, Rocky Mountains',
			src: canada2,
			key: 2,
		},
		{
			alt: 'Rocky Mountains',
			src: canada3,
			key: 3,
		},
		{
			alt: 'Grauwal, Pazifik',
			src: canada4,
			key: 4,
		},
		{
			alt: 'Schwarzbär Alaska Highway',
			src: canada5,
			key: 5,
		},
		{
			alt: 'Schwarzbären, Rocky Mountains',
			src: canada6,
			key: 6,
		},
		{
			alt: 'Adler, Rocky Mountains',
			src: canada7,
			key: 7,
		},
		{
			alt: 'Elch, Alaska Highway',
			src: canada8,
			key: 8,
		},
		{
			alt: 'Waldbison, Alaska Highway',
			src: canada9,
			key: 9,
		},
		{
			alt: 'Weißkopfseeadler, Pazifikküste',
			src: canada10,
			key: 10,
		},
		{
			alt: 'Seeotter, Pazifikküste',
			src: canada11,
			key: 11,
		},
	];
	return(
		<Carousel images={images} />
	);
}

export default CanadaCarousel;