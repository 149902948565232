import React from 'react';
import styled from 'styled-components';
import ScrumFramework from '../images/ScrumFramework.jpg';

const StyledScrumMaster = styled.span`
	display: flex;
	justify-content: space-between;
	img {
		margin-left: 10px;
		width: 40%;
		height: 40%;
		max-width: 207px;
		max-height: 300px;
	}
	@media only screen and (max-width: 600px) {
		flex-direction: column;
		img {
			width: 100%;
			height: 100%;
			margin: 1rem 0 0;
		}
	}
`;

function ScrumMaster() {
	return (
		<StyledScrumMaster>
			<span>
				Von September 2013 bis Juni 2016 war ich Scrum Master bei der ePages GmbH in Jena. Das heißt, ich war Coach, Mediator, Organisator und vor allem Beschützer von zwei Software-Entwicklungs-Teams. Nach einem ersten Scrum Basics Training 2013 habe ich im März 2014 das offizielle Scrum Master Zertifikat der Scrum Alliance erhalten. Die Erfahrungen aus dieser Rolle helfen mir heute, mein Entwicklungsteam stetig voranzubringen, Probleme zu erkennen und Veränderungen anzustoßen. Auch in der Kommunikation mit anderen Abteilungen hilft mir die Erfahrung, die ich durch Moderation vielen Meeting erhalten habe.
				<br /><br />
				Meine Aufgaben als Scrum Master umfassten:
				<ul>
					<li>
						Schutz der Scrum Teams vor Unterbrechungen und Einflüssen von außen, damit sie fokussiert arbeiten können
					</li>
					<li>
						Organisation und Moderation der Scrum Meetings
					</li>
					<li>
						Coaching von Teammitgliedern und den an Scrum beteiligten Abteilungen
					</li>
					<li>
						Unterstützung bei der Beseitigung von Impediments und der stetigen Verbesserung der Teams
					</li>
					<li>
						Mediation bei Konfliktlösungen in und zwischen den Teams
					</li>
					<li>
						Organisation und Durchführung teamübergreifender Workshops und Offsite-Meetings
					</li>
					<li>
						Erstellung und Pflege von Scrum Boards und Backlogs in Jira inklusive administrativer Anpassungen der Jira Projekte
					</li>
				</ul>
			</span>
			<img src={ScrumFramework} alt="Scrum Framework" />
		</StyledScrumMaster>
	);
}

export default ScrumMaster;
