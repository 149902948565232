import React from 'react';
import styled from 'styled-components';
import Skills from './Skills';
import Author from './Author';
import CanadaCarousel from './CanadaCarousel';
import CosplayCarousel from './CosplayCarousel';
import CreativCarousel from './CreativCarousel';
import ScrumMaster from './ScrumMaster';

const StyledTextDescription = styled.span`
	margin: 0;
	font-size: 16px;
	font-style: normal;
	text-align: justify;
	text-align-last: left;
	display: block;
	a {
		text-decoration: none;
		font-style: italic;
		color: #49274A;
		&:active {
			color: #49274A;
		}
	}
`;

function TextDescription({ focusOn = '0' }) {

	const linkClick = (e) => {
		e.stopPropagation();
	};

	return (
		<StyledTextDescription>
			{'1' === focusOn && (
				<>
					Schon mit fünfzehn Jahren habe ich angefangen zu schneidern. Damals waren es Besuche auf Mittelalter-Märkten, die mich inspirierten. Nachdem Herr der Ringe die Kinos und mein Herz eroberte, folgten die ersten Cosplays. Das Hobby ist zu einer Leidenschaft geworden, die 2019 durch die Mitgliedschaft in der Kostümgruppe
					<a onClick={linkClick} href="https://www.scifi-narische.de/" target="_blank" rel="noreferrer noopener"> "SciFi Narrische" </a>
					gekrönt wurde. Der Verein sammelt durch seine Teilnahme an verschiedenen Veranstaltungen Geld für die
					<a onClick={linkClick} href="https://www.scifi-narische.de/akb" target="_blank"  rel="noreferrer noopener"> Aktion Knochenmarkspende Bayern</a>
					. Außerdem bringen sie strahlende Gesichter und Hoffnung in Krankenhäuser und Behinderteneinrichtungen. Im Januar 2021 trat ich der <a onClick={linkClick} href="https://www.germanbaseyavin.com/" target="_blank" rel="noreferrer noopener">Rebel Legion</a> bei, die weltweit Spenden für Gemeinnützige Organisationen sammeln. Ich bin sehr stolz, mit meinem Hobby nun auch etwas Gutes tun zu können.
					<br /><br />
					<CosplayCarousel />
				</>
			)}
			{'2' === focusOn && (
				<>
					Wenn ich in den Urlaub fahre, dann muss es in die Natur gehen. Durch meinen Bruder, der 2007 nach Kanada auswanderte, habe ich meine Liebe für die Wildnis dieses Landes entdeckt. Ein ständiger Begleiter ist dabei meine Kamera, um die faszinierenden Begegnungen mit Elchen, Bären, Bisons und Walen festhalten zu können. Die schroffe Felsküste des Pazifiks ist mein Ruhepol.
					<br /><br />
					<CanadaCarousel />
				</>
			)}
			{'3' === focusOn && (
				<>
					Ob das Zeichnen von Fantasy-Karten, das Malen wilder Landschaften, das Kreieren abstrakter Bilder durch Pouring, das Herstellen von Schmuck und Lesezeichen oder das Sprühen von SciFi Träumen in Spray-Paint Art, ich muss einfach alles ausprobieren. Ich lasse mich gerne immer wieder neu inspirieren und liebe es, zur Abwechslung nicht nur an der Tastatur kreativ zu sein.
					<br /><br />
					<CreativCarousel />
				</>
			)}
			{'4' === focusOn && (
				<>
					Meine erste Festanstellung nach dem Studium war als Usability-Engineer. In dieser Rolle habe ich bestehende und neue Features auf ihre Usability untersucht, A/B-Tests im Labor gemacht und Verbesserungsvorschläge erarbeitet. Nach einem Jahr als Projekt Manager - in dem ich den Entwicklungsprozess aus einer neuen Perspektive kennengelernt habe - und drei Jahren als Scrum Master, bin ich im Juli 2016 zu meinem ursprünglichen Studiengebiet zurückgekehrt und habe als Web-Developer in der ePages GmbH gearbeitet. Dabei habe ich in einem Scrum Team JavaScript, CSS und HTML übernommen, aber auch in der Quality Insurance ausgeholfen oder Selenium Tests geschrieben. Im März 2017 zog es mich zurück in meine Heimatstadt, wo ich als Frontend Developer beim Check24 Flugsuchvergleich angefangen habe. Dort arbeitete ich unter anderem daran, das in die Jahre gekommene Frontend auf React umzuziehen. Dabei habe ich auch Erfahrungen in LESS, SCSS und PHP gesammelt. Seit Mai 2021 arbeite ich nun bei Spreadshirt, wo ich weiterhin hauptsächlich React und zusätzlich TypeScript einsetze.
					<br /><br />
					<Skills />
				</>
			)}
			{'5' === focusOn && (
				<Author />
			)}
			{'6' === focusOn && (
				<>
					Geboren und aufgewachsen vor den Toren von Leipzig, habe ich 2005 mein Abitur im Gymnasium Markranstädt gemacht. Danach zog es mich in die Innenstadt, in der ich während meines Studiums der Medien-Informatik lebte. Nur für die Masterarbeit und meine anschließende erste Festanstellung verließ ich Sachsen und zog für fünf Jahre nach Jena. Seit März 2017 lebe und arbeite ich wieder in meiner geliebten Heimatstadt.
				</>
			)}
			{'7' === focusOn && (
				<>
					Eine gute technische Ausstattung und ein hoher Praxisanteil überzeugten mich 2005 davon, Medien-Informatik an der HTWK Leipzig zu studieren. Der Jahrgang war auch der erste, in dem das Studium umstrukturiert und nur noch als Bachelor statt Diplom angeboten wurde. Daher stand für mich schnell fest, dass ich den Master of Science gleich hintendran hängen würde. 2012 schloss ich mein Studium erfolgreich ab.
				</>
			)}
			{'8' === focusOn && (
				<>
					Zwei Auslandsaufenthalte haben im Laufe der Zeit geholfen, meine Englischkenntnisse auf ein hohes Niveau zu heben. Das erste Mal verbrachte ich zwei Monate zwischen Schulabschluss und Studiumsbeginn 2005 in Bolton, England. Dort habe ich ein Praktikum absolviert und in einem multikulturellen Haushalt gelebt. Im Jahr 2011 ergriff ich die Chance, drei Monate in Kanada zu verbringen. Auch dort verbrachte ich die Zeit in einem englischsprachigen Haushalt und habe am Ende einen IELTS Test abgelegt, welchen ich mit 7,5 bestand. Auch danach habe ich beständig weiter an meinen Englischkenntnissen gearbeitet und im Jahr 2013 einen TOEIC Test mit 975 Punkten abgeschlossen.
				</>
			)}
			{'9' === focusOn && (
				<ScrumMaster/>
			)}
			{'10' === focusOn && (
				<>
			        <strong>Angaben gemäß § 5 TMG:</strong>
			      	<br /><br />
			        Anja Bärike
							<br /><br />
			        anja[@]baerike.com
			        <br /><br />
			        Gemäß § 5 TMG bin ich nicht dazu verpflichtet, ein vollständiges Impressum mit Anschrift zu veröffentlichen. Eine gültige E-Mail-Adresse ist ausreichend. Meine Anschrift kann aber über die oben stehende E-Mail-Adresse mit entsprechender Begründung angefordert werden.
			        <br /><br />
			        Quelle: <a onClick={linkClick} href="http://www.e-recht24.de" target="_blank" rel="noreferrer noopener" alt="Link zu e-recht.de">http://www.e-recht24.de</a>
			        <br /><br />
			        <strong>Haftungsausschluss (Disclaimer)</strong>
			        <br /><br />
			        <i>Haftung für Inhalte</i>
			        <br /><br />
			        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
			        <br /><br />
			        <i>Haftung für Links</i>
			        <br /><br />
			        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
			        <br /><br />
			        <i>Urheberrecht</i>
			        <br /><br />
			        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
			        <br /><br />
			        <strong>Datenschutzerklärung:</strong>
			        <br /><br />
			        Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
			        <br /><br />
			        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
			        bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
			        Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
			        möglich.<br />
			        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten
			        durch Dritte zur Übersendung von nicht ausdrücklich angeforderter
			        Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen.
			        Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte
			        im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails,
			        vor.
				</>
			)}
		</StyledTextDescription>
	);
}

export default TextDescription;