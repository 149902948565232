import React from 'react';
import styled from 'styled-components';
import TextDescription from './TextDescription';

const getHeight = (focusIn, small) => {
	switch(focusIn) {
		case '0':
			return small ? 'calc(100% - 150px)' : 'calc(100% - 300px)';
		case '1':
		case '2':
		case '3':
			return 'calc(100% - 150px)';
		case '4':
		case '5':
		case '6':
		case '7':
		case '8':
		case '9':
		case '10':
			return '75px';
	}
}

const StyledTopLine = styled.div`
	display: flex;
	justify-content: space-between;
  align-items: stretch;
	height: ${(props) => getHeight(props.focus, false)};
	> span {
		flex-grow: 0;
		text-align: center;
		display: flex;
  	align-items: center;
  	word-break: break-word;
		width: 
			${
				(props) => (
					'1' === props.focus
					|| '2' === props.focus
					|| '3' === props.focus
				) ? '20%' : 'calc(100% / 3)'
			};
		> button {
			border: none;
			width: 100%;
			height: 100%;
			padding: 10px;
			box-sizing: border-box;
			cursor: pointer;
			font-size: 20px;
			font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
			&:hover {
				font-style: italic
			}
			&:focus {
				outline: none;
			}
		}
		&.focusField {
			width: 60%;
			button {
				overflow-y: auto;
			}
		}
	}
	.color1 {
		background-color: #94618E;
	}
	.color2 {
		background-color: #F4DECB;
	}
	@media only screen and (max-width: 600px) {
		> span {
			> button {
				font-size: 16px;
			}
		}
	}
	@media only screen and (max-height: 410px) {
		height: ${(props) => getHeight(props.focus, true)};
		> span {
			> button {
				font-size: 16px;
			}
		}
	}
`;

function TopLine({ focusOn = '0', setFocus = () => {} }) {
	return (
		<StyledTopLine focus={focusOn}>
			<span className={'1' === focusOn ? 'focusField' : ''}>
				{'1' !== focusOn ? (
					<button className="color1" value="1" onClick={setFocus}>Hoffnungsbringer</button>
				) : (
					<button className="color1" onClick={setFocus} value="0">
						<TextDescription focusOn={focusOn} />
					</button>
				)}
			</span>
			<span className={'2' === focusOn ? 'focusField' : ''}>
				{'2' !== focusOn ? (
					<button className="color2" value="2" onClick={setFocus}>Naturfreund</button>
				) : (
					<button className="color2" onClick={setFocus} value="0">
						<TextDescription focusOn={focusOn} />
					</button>
				)}
			</span>
			<span className={'3' === focusOn ? 'focusField' : ''}>
				{'3' !== focusOn ? (
					<button className="color1" value="3" onClick={setFocus}>Kreativmensch</button>
				) : (
					<button className="color1" onClick={setFocus} value="0">
						<TextDescription focusOn={focusOn} />
					</button>
				)}
			</span>
		</StyledTopLine>
	);
}

export default TopLine;
