import React from 'react';
import Carousel from './Carousel';
import creativ0 from '../images/creativ/creativ0.jpg';
import creativ1 from '../images/creativ/creativ1.jpg';
import creativ2 from '../images/creativ/creativ2.jpg';
import creativ3 from '../images/creativ/creativ3.jpg';
import creativ4 from '../images/creativ/creativ4.jpg';
import creativ5 from '../images/creativ/creativ5.jpg';
import creativ6 from '../images/creativ/creativ6.jpg';
import creativ7 from '../images/creativ/creativ7.jpg';
import creativ8 from '../images/creativ/creativ8.jpg';
import creativ9 from '../images/creativ/creativ9.jpg';
import creativ10 from '../images/creativ/creativ10.jpg';
import creativ11 from '../images/creativ/creativ11.jpg';
import creativ12 from '../images/creativ/creativ12.jpg';

function CreativCarousel() {
const images = [
		{
			alt: 'Abstract Painting red',
			src: creativ0,
			key: 0,
		},
		{
			alt: 'Spray Paint Art - Star Wars',
			src: creativ1,
			key: 1,
		},
		{
			alt: 'Pouring',
			src: creativ2,
			key: 2,
		},
		{
			alt: 'Schmucklesezeichen - Steampunk',
			src: creativ3,
			key: 3,
		},
		{
			alt: 'Spray Paint Art - Gefährten',
			src: creativ4,
			key: 4,
		},
		{
			alt: 'Pouring',
			src: creativ5,
			key: 5,
		},
		{
			alt: 'Spray Paint Art - Good Omens',
			src: creativ6,
			key: 6,
		},
		{
			alt: 'Kette - Magic Ink',
			src: creativ7,
			key: 7,
		},
		{
			alt: 'Spray Paint Art - Star Wars',
			src: creativ8,
			key: 8,
		},
		{
			alt: 'Schmucklesezeichen - leuchtend',
			src: creativ9,
			key: 9,
		},
		{
			alt: 'Pouring',
			src: creativ10,
			key: 10,
		},
		{
			alt: 'Schmuckset - Steampunk',
			src: creativ11,
			key: 11,
		},
		{
			alt: 'Abstract Painting brown',
			src: creativ12,
			key: 12,
		},
	];
	return(
		<Carousel images={images} />
	);
}

export default CreativCarousel;