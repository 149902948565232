import React from 'react';
import AnjaBärike from '../images/AnjaBärike.jpg';
import styled from 'styled-components';
import TextDescription from './TextDescription';

const getHeight = (focusIn, small) => {
	switch(focusIn) {
		case '0':
			return small ? '150px' : '300px';
		case '1':
		case '2':
		case '3':
		case '6':
		case '7':
		case '8':
		case '9':
		case '10':
			return '75px';
		case '4':
		case '5':
			return 'calc(100% - 150px)';
	}
}

const getWidth = (focusIn, small) => {
	switch(focusIn) {
		case '0':
			return small ? 'calc(50% - 50px)' : 'calc(50% - 100px)';
		case '1':
		case '2':
		case '3':
		case '6':
		case '7':
		case '8':
		case '9':
		case '10':
			return 'calc(50% - 25px)';
		case '4':
		case '5':
			return '20%';
	}
}

const getImgWidth = (focusIn, container, small) => {
	switch(focusIn) {
		case '0':
			return small ? '100px' : '200px';
		case '1':
		case '2':
		case '3':
		case '6':
		case '7':
		case '8':
		case '9':
		case '10':
			return '50px';
		case '4':
		case '5':
			return container ? '20%' : '-webkit-fill-available';
	}
}

const getImgHeight = (focusIn, small) => {
	switch(focusIn) {
		case '0':
			return small ? '150px' : '300px';
		case '1':
		case '2':
		case '3':
		case '6':
		case '7':
		case '8':
		case '9':
		case '10':
			return '75px';
		case '4':
		case '5':
			return 'auto';
	}
}

const StyledMiddleLine = styled.div`
	display: flex;
	justify-content: space-between;
  align-items: stretch;
	flex-grow: 0;
	height: ${(props) => getHeight(props.focus, false)};
	> span {
		flex-grow: 0;
		text-align: center;
		display: flex;
  	align-items: center;
  	word-break: break-word;
		width: ${(props) => getWidth(props.focus, false)};
		button {
			border: none;
			width: 100%;
			height: 100%;
			padding: 10px;
			box-sizing: border-box;
			cursor: pointer;
			font-size: 20px;
			font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
			&:hover {
				font-style: italic
			}
			&:focus {
				outline: none;
			}
		}
		&.focusField {
			width: 60%;
			button {
				overflow-y: auto;
			}
		}
	}
	.mainImage {
		width: ${(props) => getImgWidth(props.focus, true, false)};
		flex-grow: 0;
		padding: 0;
		background-color: #F8EEE7;
		cursor: ${(props) => ('0' === props.focus ? 'default' : 'pointer')};
		img {
			height: ${(props) => getImgHeight(props.focus, false)};
			width: ${(props) => getImgWidth(props.focus, false, false)};
		}
	}
	.color4 {
		background-color: #49274A;
		color: #F8EEE7;
	}
	@media only screen and (max-width: 600px) {
		height: ${(props) => getHeight(props.focus, true)};
		> span {
			width: ${(props) => getWidth(props.focus, true)};
			button {
				font-size: 16px;
			}
		}
		.mainImage {
			width: ${(props) => getImgWidth(props.focus, true, true)};
			img {
				height: ${(props) => getImgHeight(props.focus, true)};
				width: ${(props) => getImgWidth(props.focus, false, true)};
			}
		}
	}
	@media only screen and (max-height: 410px) {
		height: ${(props) => getHeight(props.focus, true)};
		> span {
			width: ${(props) => getWidth(props.focus, true)};
			button {
				font-size: 16px;
			}
		}
		.mainImage {
			width: ${(props) => getImgWidth(props.focus, true, true)};
			img {
				height: ${(props) => getImgHeight(props.focus, true)};
				width: ${(props) => getImgWidth(props.focus, false, true)};
			}
		}
	}
`;

function TopLine({ focusOn = '0', setFocus = () => {}, resetFocus = () => {} }) {
	return (
		<StyledMiddleLine focus={focusOn}>
			<span className={'4' === focusOn ? 'focusField' : ''}>
				{'4' !== focusOn ? (
					<button className="color4" value="4" onClick={setFocus}>Frontend Entwicklerin</button>
				) : (
					<button className="color4" onClick={setFocus} value="0">
						<TextDescription focusOn={focusOn} />
					</button>
				)}
			</span>
			<span className="mainImage" role="presentation" onClick={resetFocus}>
				<img src={AnjaBärike} alt="Anja Bärike" />
			</span>
			<span className={'5' === focusOn ? 'focusField' : ''}>
				{'5' !== focusOn ? (
					<button className="color4" value="5" onClick={setFocus}>Autorin</button>
				) : (
					<button className="color4" onClick={setFocus} value="0">
						<TextDescription focusOn={focusOn} />
					</button>
				)}
			</span>
		</StyledMiddleLine>
	);
}

export default TopLine;
