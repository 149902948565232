import React from 'react';
import styled from 'styled-components';
import DieSeeleDerSonneCover from '../images/DieSeeleDerSonneCover.jpg';
import DilarasWegtraeumGeschichtenCover from '../images/DilarasWegtraeumGeschichten.jpg';
import DasLeuchtenDerWeihnacht from '../images/DasLeuchtenDerWeihnacht.jpg';

const StyledAuthor = styled.span`
	display: flex;
	justify-content: space-between;
	.images {
		display: flex;
		img {
			margin: 5px;
		}
	}
	.link {
		color: #F4DECB;
		&:active {
			color: #94618E;
		}
	}
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
	}
	@media only screen and (max-width: 750px) {
		.images {
			flex-direction: column;
			align-items: center;
		}
	}
`;

function Author() {
	const linkClick = (e) => {
		e.stopPropagation();
	};

	return(
		<StyledAuthor>
			<span>
				Schon in der Grundschule entdeckte ich meine Leidenschaft fürs Schreiben durch einen Kurzgeschichtenwettbewerb, bei dem meine Geschichte zu den zwei Siegern gehörte, welche in der Schülerzeitung abgedruckt wurden. Durch Schul- und Studienzeit hindurch habe ich das Schreiben weiter verfolgt und meine Fähigkeiten ausgebaut, bis ich 2015 mein ersten Buch
				<a className="link" onClick={linkClick} href="https://www.amazon.de/gp/product/B00YHKNU0Q" target="_blank"  rel="noreferrer noopener"> "Die Seele der Sonne" </a>
				veröffentlichte. Der Jugend-Fantasy-Roman ist ein Herzensprojekt, welches nicht allein bleiben soll. Meine erste Fantasy Kurzgeschichte "Weltenwanderer" wurde im November 2020 in der Anthologie
				<a className="link" onClick={linkClick} href="https://www.amazon.de/dp/B08KWKD1GH/" target="_blank"  rel="noreferrer noopener"> "Dilaras Wegträumgeschichten" </a>
				veröffentlicht. Die zweite erschien 2022 in der FaKriRo Weihnachtsanthologie <a className="link" onClick={linkClick} href="https://amzn.eu/d/hY46Nov" target="_blank"  rel="noreferrer noopener">Das Leuchten der Weihnacht</a> unter dem Namen "Winterfeenzauber".
			</span>
			<span className="images">
				<img src={DieSeeleDerSonneCover} alt="Die Seele der Sonne Cover" />
				<img src={DilarasWegtraeumGeschichtenCover} alt="Dilaras Wegträum Geschichten" />
				<img src={DasLeuchtenDerWeihnacht} alt="Das Leuchten der Weihnacht" />
			</span>
		</StyledAuthor>
	);
}

export default Author;
