import React from 'react';
import Star from '../images/Star';
import styled from 'styled-components';

function Skills() {
	const skills = [
		{
			text: 'JavaScript',
			value: 5,
			kex: 'JS',
		},
		{
			text: 'React',
			value: 4,
			key: 'React',
		},
		{
			text: 'CSS, SCSS, LESS',
			value: 5,
			key: 'CSS',
		},
		{
			text: 'HTML',
			value: 4,
			key: 'HTML',
		},
		{
			text: 'PHP',
			value: 2,
			key: 'PHP',
		},
	];

	const StyledSkills = styled.span`
		display: flex;
		svg {
			width: 20px;
		}
		> span:first-of-type {
			width: 130px;
			flex-grow: 0;
			flex-shrink: 0;
		}
	`;

	return skills.map((skill) => (
		<StyledSkills key={skill.key}>
			<span>{skill.text}</span>
			<Star filled={skill.value >= 1} />
			<Star filled={skill.value >= 2} />
			<Star filled={skill.value >= 3} />
			<Star filled={skill.value >= 4} />
			<Star filled={5 === skill.value} />
		</StyledSkills>
	));
}

export default Skills;