import React from 'react';
import styled from 'styled-components';
import TextDescription from './TextDescription';

const getHeight = (focusIn, small) => {
	switch(focusIn) {
		case '0':
			return small ? 'calc(100% - 150px)' : 'calc(100% - 300px)';
		case '1':
		case '2':
		case '3':
		case '4':
		case '5':
			return '75px';
		case '6':
		case '7':
		case '8':
		case '9':
		case '10':
			return 'calc(100% - 150px)';
	}
}

const StyledBottomLine = styled.div`
	display: flex;
	justify-content: space-between;
  align-items: stretch;
	flex-grow: 1;
	height: ${(props) => getHeight(props.focus, false)};
	> span {
		flex-grow: 0;
		text-align: center;
		display: flex;
  	align-items: center;
  	word-break: break-word;
		width: 
			${
				(props) => (
					'6' === props.focus
					|| '7' === props.focus
					|| '8' === props.focus
					|| '9' === props.focus
					|| '10' === props.focus
				) ? '10%' : 'calc(100% / 5)'
			};
		button {
			border: none;
			width: 100%;
			height: 100%;
			padding: 10px;
			box-sizing: border-box;
			cursor: pointer;
			font-size: 20px;
			font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
			&:hover {
				font-style: italic
			}
			&:focus {
				outline: none;
			}
		}
		&.focusField {
			width: 60%;
			button {
				overflow-y: auto;
			}
		}
	}
	.color1 {
		background-color: #94618E;
	}
	.color2 {
		background-color: #F4DECB;
	}
	.color3 {
		background-color: #F8EEE7;
	}
	@media only screen and (max-width: 600px) {
		> span {
			button {
				font-size: 16px;
			}
		}
	}
	@media only screen and (max-height: 410px) {
		height: ${(props) => getHeight(props.focus, true)};
		> span {
			button {
				font-size: 16px;
			}
		}
	}
`;

function TopLine({ focusOn = '0', setFocus = () => {} }) {
	return (
		<StyledBottomLine focus={focusOn}>
			<span className={'6' === focusOn ? 'focusField' : ''}>
				{'6' !== focusOn ? (
					<button className="color1" value="6" onClick={setFocus}>Leipzigerin</button>
				) : (
					<button className="color1" onClick={setFocus} value="0">
						<TextDescription focusOn={focusOn} />
					</button>
				)}
			</span>
			<span className={'7' === focusOn ? 'focusField' : ''}>
				{'7' !== focusOn ? (
					<button className="color2" value="7" onClick={setFocus}>Master of Science</button>
				) : (
					<button className="color2" onClick={setFocus} value="0">
						<TextDescription focusOn={focusOn} />
					</button>
				)}
			</span>
			<span className={'8' === focusOn ? 'focusField' : ''}>
				{'8' !== focusOn ? (
					<button className="color3" value="8" onClick={setFocus}>Weltenbummler</button>
				) : (
					<button className="color3" onClick={setFocus} value="0">
						<TextDescription focusOn={focusOn} />
					</button>
				)}
			</span>
			<span className={'9' === focusOn ? 'focusField' : ''}>
				{'9' !== focusOn ? (
					<button className="color2" value="9" onClick={setFocus}>Scrum Master</button>
				) : (
					<button className="color2" onClick={setFocus} value="0">
						<TextDescription focusOn={focusOn} />
					</button>
				)}
			</span>
			<span className={'10' === focusOn ? 'focusField' : ''}>
				{'10' !== focusOn ? (
					<button className="color1" value="10" onClick={setFocus}>Impressum</button>
				) : (
					<button className="color1" onClick={setFocus} value="0">
						<TextDescription focusOn={focusOn} />
					</button>
				)}
			</span>
		</StyledBottomLine>
	);
}

export default TopLine;
