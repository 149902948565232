import React, { useState } from 'react';
import styled from 'styled-components';
import TopLine from './TopLine';
import BottomLine from './BottomLine';
import MiddleLine from './MiddleLine';

const StyledMain = styled.div`
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	justify-content: space-between;
`;

function Start() {
	const [focusOn, setFocusOn] = useState('0');

	const setFocus = (e) => {
		setFocusOn(undefined === e.target.value ? '0' : e.target.value);
	}

	const resetFocus = () => {
		setFocusOn('0');
	};

	return (
		<StyledMain>
			<TopLine focusOn={focusOn} setFocus={setFocus} />
			<MiddleLine focusOn={focusOn} setFocus={setFocus} resetFocus={resetFocus} />
			<BottomLine focusOn={focusOn} setFocus={setFocus} />
		</StyledMain>
	);
}

export default Start;
