import React from 'react';
import Carousel from './Carousel';
import cosplay0 from '../images/cosplay/cosplay0.jpg';
import cosplay1 from '../images/cosplay/cosplay1.jpg';
import cosplay2 from '../images/cosplay/cosplay2.jpg';
import cosplay3 from '../images/cosplay/cosplay3.jpg';
import cosplay4 from '../images/cosplay/cosplay4.jpg';
import cosplay5 from '../images/cosplay/cosplay5.jpg';
import cosplay6 from '../images/cosplay/cosplay6.jpg';
import cosplay7 from '../images/cosplay/cosplay7.jpg';
import cosplay8 from '../images/cosplay/cosplay8.jpg';
import cosplay9 from '../images/cosplay/cosplay9.jpg';
import cosplay10 from '../images/cosplay/cosplay10.jpg';

function CosplayCarousel() {
const images = [
		{
			alt: 'Bayernpark Sternenkrieger und Fantasy Treffen',
			src: cosplay0,
			key: 0,
		},
		{
			alt: 'Piraten - Speyer Technikmuseum',
			src: cosplay1,
			key: 1,
		},
		{
			alt: 'Jedi - Speyer Technikmuseum',
			src: cosplay2,
			key: 2,
		},
		{
			alt: 'Vampirballkleid - Cosplay Fotoshooting',
			src: cosplay3,
			key: 3,
		},
		{
			alt: 'Demeter und Persephone - Walhalla Regensburg',
			src: cosplay4,
			key: 4,
		},
		{
			alt: 'Galaxy Quest - Bayernpark Sternenkrieger und Fantasy Treffen',
			src: cosplay5,
			key: 5,
		},
		{
			alt: 'Helga Hufflepuff - Cosplay Fotoshooting',
			src: cosplay6,
			key: 6,
		},
		{
			alt: 'Elbenkleid - RingCon',
			src: cosplay7,
			key: 7,
		},
		{
			alt: 'Elbenkrieger - Buchmesse Leipzig',
			src: cosplay8,
			key: 8,
		},
		{
			alt: 'Elbenkleid - HobbitCon',
			src: cosplay9,
			key: 9,
		},
		{
			alt: 'Woman of Rohan - RingCon',
			src: cosplay10,
			key: 10,
		},
	];
	return(
		<Carousel images={images} />
	);
}

export default CosplayCarousel;