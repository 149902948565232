import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledCarousel = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	.show {
		display: block;
	}
	.hide {
		display: none;
	}
	.mover {
		cursor: pointer;
		padding: 10px;
	}
	svg {
		fill: #49274A;
		&:hover {
			fill: #F8EEE7;
		}
	}
	img {
		width: calc(100% - 90px);
		max-width: 400px;
	}
`;

function Carousel({ images = [] }) {
	const [visibleImg, setVisibleImg] = useState(0);

	const previousImage = (e) => {
		e.stopPropagation();
		if (0 === visibleImg) {
			setVisibleImg(images.length - 1);
		} else {
			setVisibleImg(visibleImg - 1);
		}
	}
	const nextImage = (e) => {
		if (undefined !== e) {
			e.stopPropagation();
		}
		if ((images.length - 1) === visibleImg) {
			setVisibleImg(0);
		} else {
			setVisibleImg(visibleImg + 1);
		}
	}

	useEffect(() => {
		const sliderTimer = window.setInterval(() => {
			nextImage();
		}, 3000);
		return () => clearInterval(sliderTimer);
	}, [visibleImg])

	return (
		<StyledCarousel>
			<span className="mover" onClick={previousImage}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 64.3" width="24" height="24" transform="rotate(90)">
					<path d="M50 64.3l50-51.4L87.9 0 50 40.7 12.1 0 0 12.9z"></path>
				</svg>
			</span>
			{images.map((image) => (
				<img key={image.key} className={visibleImg === image.key ? 'show' : 'hide'} src={image.src} alt={image.alt} />
			))}
			<span className="mover" onClick={nextImage}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 64.3" width="24" height="24" transform="rotate(-90)">
					<path d="M50 64.3l50-51.4L87.9 0 50 40.7 12.1 0 0 12.9z"></path>
				</svg>
			</span>
		</StyledCarousel>
	);
}

export default Carousel;
